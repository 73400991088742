import React from "react"
import Layout from '../components/Layout'
import {Link} from 'gatsby'
import {Location} from '@reach/router'
import Header from '../components/Header'
import Content from '../components/Content'
import '../utils/usalarms.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUnlock,faExclamation,faBolt,faMedkit} from '@fortawesome/free-solid-svg-icons';
export default () => (
    <Location>
        {
            props=>(
                <Layout location={props.location}>
                    <Header>
                        <h1>Home Security and Automation</h1>
                        <h2>PROUDLY SECURING ALL OF CENTRAL FLORIDA</h2>
                        <div className="row">
                        <Link to="/free-home-evaluation" className="btn primary">
                            Request Free Home Evaluation
                        </Link>
                        </div>
                    </Header>
                    <Content>
                       <h2 className="text-center animated faster q1" data-animatequeue="slideInUp">Our Services</h2>
                       <p className="text-center animated faster q1" data-animatequeue="slideInUp">
                           We provide <Link to="/services">home security and automation solutions</Link> tailored to your individual home or office.
                       </p>
                       
                       <div className="row service-list q1">
                           <div className="col animated faster q2 column" data-animatequeue="fadeInLeft">
                               <div className="hexagon primary">
                                 <FontAwesomeIcon icon={faUnlock}/>
                               </div>
                               
                             <Link to="/services/access-control">ACCESS CONTROL</Link><br />
                           <p>Manage Access To Your Property. Control who has access to your property, and when, with integrated control for door locks and garage doors.</p>
                           </div>
                           <div className="col column animated faster q2" data-animatequeue="fadeInLeft">
                           <div className="hexagon primary">
                                 <FontAwesomeIcon icon={faExclamation}/>
                               </div>
                            <Link to="/services/home-monitoring">24/7 MONITORING</Link><br />
                           <p>Professionally Monitored Security. Keep your property safe with 24/7 professional monitoring and emergency response.</p>
                           </div>
                           <div className="col column animated faster q2" data-animatequeue="fadeInLeft">
                           <div className="hexagon primary">
                                 <FontAwesomeIcon icon={faBolt}/>
                               </div>
                            <Link to="/services/energy-management">ENERGY MANAGEMENT</Link><br />
                           <p>Smarter Energy Savings. US Alarms connects your thermostats and lighting to your security system for a smarter, more responsive set of options for saving energy.</p>
                           </div>
                           <div className="col column animated faster q2" data-animatequeue="fadeInLeft">
                           <div className="hexagon primary">
                                 <FontAwesomeIcon icon={faMedkit}/>
                               </div>
                               <Link to="/services/health-wellness-monitoring">HEALTH & WELLNESS MONITORING</Link><br />
                           <p>More than an emergency button - a complete 24/7 safety net. US Alarms Wellness offers a secure and cost-effective option for independent living with safety, security, and comfort.</p>
                           </div>
                         
                       </div>
                    </Content>
                </Layout>
            )
        }
    </Location>
)
